<script setup lang="ts">
import Spinner from './Spinner.vue'
import type { VariantType, SizeType } from '~/types'
import { Variant, Size } from '~/types'

const props = withDefaults(defineProps<{
  variant?: VariantType,
  size?: SizeType
  outlined?: boolean,
  gradient?: boolean,
  disabled?: boolean,
  preventDefault?: boolean,
  stopPropagation?: boolean,
  wide?: boolean,
  loading?: boolean,
  to?: string,
  type?: 'button' | 'submit',
}>(), {
  variant: Variant.PRIMARY,
  size: Size.MEDIUM,
  type: 'button',
})

const emit = defineEmits(['click'])

const classList = computed(() => {
  const _classList: string[] = []

  if (props.outlined) {
    _classList.push('border-2')
    if (props.variant === Variant.NEUTRAL) {
      _classList.push('text-white border-white hover:bg-white/10')
    } else if (props.variant === Variant.SECONDARY) {
      _classList.push('text-secondary border-secondary hover:bg-secondary/10')
    } else if (props.variant === Variant.SUCCESS) {
      _classList.push('text-success border-success hover:bg-success/10')
    } else if (props.variant === Variant.ERROR) {
      _classList.push('text-error border-error hover:bg-error/10')
    } else if (props.variant === Variant.BLACK) {
      _classList.push('text-black border-black hover:bg-black/10')
    } else if (props.variant === Variant.WHITE) {
      _classList.push('text-white border-white hover:bg-white/10')
    } else {
      _classList.push('text-primary border-primary hover:bg-primary/10')
    }

    // if (props.gradient) {
    //   _classList.push('border-2 border-transparent bg-clip-padding border-gradient-to-r')
    // }
  } else {
    if (props.variant === Variant.WHITE) {
      _classList.push('text-black')
    } else {
      _classList.push('text-white')
    }

    if (props.gradient) {
      if (props.variant === Variant.SECONDARY) {
        _classList.push('bg-gradient-to-r from-secondary-start to-secondary-end hover:from-secondary-start/85 hover:to-secondary-end/85')
      } else if (props.variant === Variant.SUCCESS) {
        _classList.push('bg-gradient-to-r from-success-start to-success-end hover:from-success-start/85 hover:to-success-end/85')
      } else if (props.variant === Variant.ERROR) {
        _classList.push('bg-gradient-to-r from-error-start to-error-end hover:from-error-start/85 hover:to-error-end/85')
      } else if (props.variant === Variant.BLACK) {
        _classList.push('bg-gradient-to-r from-black-start to-black-end hover:from-black-start/85 hover:to-black-end/85')
      } else if (props.variant === Variant.WHITE) {
        _classList.push('bg-gradient-to-r from-white-start to-white-end hover:from-white-start/85 hover:to-white-end/85')
      } else {
        _classList.push('bg-gradient-to-r from-primary-start to-primary-end hover:from-primary-start/85 hover:to-primary-end/85')
      }
    } else {
      if (props.variant === Variant.NEUTRAL) {
        _classList.push('bg-neutral hover:bg-neutral/85')
      } else if (props.variant === Variant.SECONDARY) {
        _classList.push('bg-secondary hover:bg-secondary/85')
      } else if (props.variant === Variant.SUCCESS) {
        _classList.push('bg-success hover:bg-success/85')
      } else if (props.variant === Variant.ERROR) {
        _classList.push('bg-error hover:bg-error/85')
      } else if (props.variant === Variant.BLACK) {
        _classList.push('bg-black hover:bg-black/85')
      } else if (props.variant === Variant.WHITE) {
        _classList.push('bg-white hover:bg-white/85')
      } else {
        _classList.push('bg-primary hover:bg-primary/85')
      }
    }
  }

  if (props.size === Size.SMALL) {
    _classList.push('min-h-8 h-8 px-4 text-xs')
  } else if (props.size === Size.MEDIUM) {
    _classList.push('min-h-10 h-10 px-6 text-sm')
  } else {
    _classList.push('min-h-14 h-14 px-8 text-md')
  }

  if (props.disabled) {
    _classList.push('opacity-50 cursor-not-allowed focus:outline-none')
  }

  if (props.wide) {
    _classList.push('w-full')
  }

  return _classList.join(' ')
})

const onClick = (e: MouseEvent) => {
  if (props.disabled) {
    return
  }

  if (props.preventDefault) {
    e.preventDefault()
  }

  if (props.stopPropagation) {
    e.stopPropagation()
  }


  if (props.to) {
    navigateTo(props.to)
  }

  emit('click')
}
</script>

<template>
  <button class="text-center focus:outline-none focus:ring-none rounded-[30px] font-bold" :class="classList" :type="type"
    @click="onClick">
    <div v-if="loading" class="w-full flex justify-center items-center">
      <Spinner />
    </div>
    <template v-else>
      <slot />
    </template>
  </button>
</template>
